.engineering_company_slider .slick-track {
  margin-top: 70px;
  margin-bottom: 70px;
}

/* Hide next/previous buttons */
.engineering_company_slider .slick-prev{
  display: none !important;
}
.engineering_company_slider .slick-next {
  display: none !important;
}

/* Hide bottom active dots */
.engineering_company_slider .slick-dots {
  display: none !important;
}