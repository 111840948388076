/* Button with glowing border loop */
.glowingButtons {
    background: #ffffff;
    font-family: 'Poppins', sans-serif;
    color:#0168FF;
    font-weight: 600;
    font-size: 18px;
    min-width: 12vw;
    padding: 10px 40px;
    border: 0px solid transparent;
    border-radius: 45px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    letter-spacing: 1px;
    cursor: pointer;
    outline: none !important;
    border: none;
}

.glowingButtons:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }

.glowingButtons::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(120deg, #f4b109, #FBC53B); 
    border-radius: 40px;
    z-index: -1; 
    animation: glow-border 3s linear infinite;
}

@keyframes glow-border {
    0% {
        transform: translate(-50%, -50%);
    }
    100% {
        transform: translate(0%, 0%);
    }
}
